$mobile-width: 375px;
$xsmall-width: 480px;
$small-width: 568px;
$tablet-width: 768px;
$tablet-large-width: 950px;
$desktop-small-width: 1024px;
$desktop-small-width-campaign: 1050px;
$desktop-width: 1180px;
$desktop-width-campaign: 1200px;
$desktop-medium-width: 1440px;
$desktop-large-width: 1920px;

$desktop-small-container-width: 900px;
$desktop-medium-container-width: 1100px;
$desktop-large-container-width: 1300px;

@mixin horizontal-gradient() {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 3%, var(--finder-detail-grey) 10%, var(--finder-detail-grey) 90%, rgba(255, 255, 255, 0) 97%);
}

@mixin wide-horizontal-rule() {
    height: var(--wide-horizontal-rule-mixin-height, 50px);
    background: var(--finder-theme-bg-color);
    background-image: var(--wide-horizontal-rule-mixin-background-image);
    border-bottom: var(--wide-horizontal-rule-mixin-border-bottom);
    border-top: var(--wide-horizontal-rule-mixin-border-top);
    box-sizing: border-box;
}

@mixin disclaimer() {
    @include type14;
    color: var(--disclaimer-mixin-color, var(--finder-message-grey));
}

@mixin vertical-gradient() {
    flex: 0 0 1px;
    height: auto;
    width: 1px;
    background-color: transparent;
    background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.00) 0%, var(--finder-detail-grey) 16%, var(--finder-detail-grey) 83%, rgba(255, 255, 255, 0.00) 96%)
}

@mixin pseudo-hr() {
    content: ' ';
    display: block;
    height: 1px;
    width: 100%;
    background: var(--finder-detail-grey);
}

@mixin screen-reader-text() {
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal;
}

@mixin screen-reader-focus() {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

@mixin user-select-none() {
    user-select: none;
}

@mixin nowrap() {
    white-space: nowrap;
}

@mixin focus() {
    color: var(--focus-mixin-color);
    text-decoration-thickness: var(--focus-mixin-text-decoration-thickness);
    // Update the font-icon color when hover happens
    --font-icon-svg-background-color: var(--focus-mixin-svg-color);
}

@mixin page-padding-xs() {
    padding-left: 15px;
    padding-right: 15px;
}

@mixin page-padding-sm() {
    padding-left: 20px;
    padding-right: 20px;
}

@mixin page-padding-md() {
    padding-left: 88px;
    padding-right: 88px;
}

@mixin page-padding-lg() {
    padding-left: 70px;
    padding-right: 70px;
}

@mixin page-fullwidth() {
    margin: auto calc(-1 * (100vw - 100%)/2);
}

@mixin no-highlight-selection() {
    user-select: none;          /* Likely future */
}

@mixin desktop-container {
    max-width: $desktop-medium-container-width;
    margin-left: auto;
    margin-right: auto;
}

@mixin desktop-campaign-container {
    max-width: $desktop-large-container-width;
    margin-left: auto;
    margin-right: auto;
}

@mixin desktop-large-container {
    max-width: $desktop-large-container-width;
    margin-left: auto;
    margin-right: auto;
}

@mixin mobile {
    @media (min-width: $mobile-width) {
        @content;
    }
}

@mixin mobile-view {
    @media (max-width: $tablet-width) {
        @content;
    }
}

@mixin xsmall {
    @media (min-width: $xsmall-width) {
        @content;
    }
}

@mixin small {
    @media (min-width: $small-width) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $tablet-width) {
        @content;
    }
}

@mixin tablet-large {
    @media (min-width: $tablet-large-width) {
        @content;
    }
}

@mixin desktop-small {
    @media (min-width: $desktop-small-width) {
        @content;
    }
}

@mixin desktop-small-campaign {
    @media (min-width: $desktop-small-width-campaign) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-width) {
        @content;
    }
}

@mixin desktop-campaign {
    @media (min-width: $desktop-width-campaign) {
        @content;
    }
}

@mixin campaign-container {
    @media (min-width: $desktop-large-container-width) {
        @content;
    }
}

@mixin desktop-medium {
    @media (min-width: $desktop-medium-width) {
        @content;
    }
}

@mixin desktop-large {
    @media (min-width: $desktop-large-width) {
        @content;
    }
}

@mixin notched-corner($width, $height, $bg-color: inherit, $border-color: transparent, $border-width: 2px, $rotate: 45deg, $transform-origin: center) {
    content: " ";
    position: absolute;
    width: $width;
    height: $height;
    background-color: $bg-color;
    transform: rotate($rotate);
    border-left: $border-width solid $border-color;
    transform-origin: $transform-origin;
}

// Creates a comma separated list.
// $class can be whatever element you need.
// For example: .schedule, .name, etc.
@mixin comma-separated($class) {
    .#{$class}:not(:last-child):after {
        content: ',';
        margin-right: 2px;
    }
}

// Creates a flex element center aligned.
@mixin fully-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

// Show the caret for drawer components
// Place the caret at the bottom of the card
// IMPORTANT: Be sure to add the css var defaults wherever this mixin is used
@mixin showCaret($bottom, $border-color: transparent, $background-color: transparent, $left: 5%) {
    &.show-caret::after {
        content: '';
        position: absolute;
        height: 15px;
        width: 15px;
        transform: rotate(-45deg);
        bottom: $bottom;
        left: $left;
        z-index: 951;
        background: $background-color;
        border: 1px solid $border-color;
        border-width: 1px 1px 0 0;
    }
}

// Hide a bunch of stuff so the print view displays
// just the content we want.
@media print {

    .finder-header,
    .finder-footer,
    .debug-panel {
        display: none;
    }

    .finder-content .content .campaign {
        .campaign-content .campaign-top .print-button {
            display: none;
        }
    }
}

body {
    --focus-mixin-color: var(--finder-theme-primary-alt-state-color);
    --focus-mixin-svg-color: var(--finder-theme-primary-alt-state-color);
}
