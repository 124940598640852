@import 'styles';

$icon-font: pepmdx;

:host {
    --list-cluster-color: var(--finder-theme-primary-color);
    --list-cluster-background: var(--finder-theme-bg-color);
    --list-cluster-border: var(--finder-theme-primary-color);
    --list-cluster-focus-color: var(--finder-theme-bg-color);
    --list-cluster-focus-outline-color: var(--finder-theme-bg-color);
    --list-cluster-focus-background: var(--finder-theme-primary-color);
    --list-cluster-font: var(--finder-theme-primary-font);
    --map-facet-text-color: var(--finder-outline-color);
    --list-cluster-focus-control-border-color: var(--finder-basic-black);
    --list-cluster-focus-control-color: var(--finder-basic-black);
    --list-cluster-map-facet-text-color: var(--map-facet-text-color);
}

@mixin setupIcon() {
    font-family: $icon-font;
    vertical-align: middle;
    font-size: 1.25em;
}

.leaflet-container {
	font-family: var(--finder-theme-primary-font);
    font-size: 16px;
}

div.leaflet-bar {
    a.icon {
        width: auto;
        min-width: 30px;
        min-height: 30px;

        span.recenter-icon {
            &::before {
                @include setupIcon;
                content: "\e46a";
            }
        }

        span.expand-icon {
            margin: 0 5px;

            &::before {
                @include setupIcon;
                content: "\e337";
                margin-right: 3px;
            }
        }

        span.display-list {
            margin: 0 5px;

            &::before {
                @include setupIcon;
                content: "\e318";
                margin-right: 3px;
            }
        }
    }
}

.leaflet-container a.leaflet-popup-close-button {
    color: #798E99;

    &:hover {
        color: #000;
    }
}

.map,
#campaign-map {
    $cluster_small: 27px;

    --list-cluster-color: var(--finder-theme-primary-color);
    --list-cluster-background: var(--finder-theme-bg-color);
    --list-cluster-border: var(--finder-theme-primary-color);
    --list-cluster-focus-color: var(--finder-theme-bg-color);
    --list-cluster-focus-outline-color: var(--finder-theme-primary-color);
    --list-cluster-focus-background: var(--finder-theme-primary-color);
    --list-cluster-font: var(--finder-theme-primary-font);
    --map-facet-text-color: var(--finder-outline-color);

    .leaflet-popup-content-wrapper {
        width: 400px;
    }

    .leaflet-popup-content {
        display: flex;
        margin: 15px 10px;
        width: 100%;
        box-sizing: border-box;

        &:has(.has-debug) {
            overflow: auto;
        }
    }

    .leaflet-div-icon {
        background: none;
        border: none;
    }

    // When the wait time is displayed we move the popup under the pin
    .leaflet-popup {
        &.under {
            .leaflet-popup-tip-container {
                top: -18px;
                transform: rotate(180deg);
            }

            .leaflet-popup-content {
                min-height: 160px; // Set a minium size for a popup with a wait time
            }
        }

        // Standardize the size of the map popup when the dpa button is present
        &.dpa-spacing {
            .leaflet-popup-content {
                min-height: 230px;
                align-content: center;
            }
        }

        // Center the content within the map popup card
        finder-map-card {
            --map-card-container-wrap: wrap;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
    }

    .leaflet-marker-icon {
        &:focus,
        &:hover {
            // The focus version of this gets bigger and the background color changes
            .marker-numbered {
                transform: scale(1.2);
                background-color: var(--list-cluster-focus-background);
                color: var(--list-cluster-focus-color);
            }
        }
        .marker-numbered {
            width: $cluster_small;
            height: $cluster_small;
            color: var(--list-cluster-color);
            font-family: var(--list-cluster-font);
            font-weight: bold;
            font-size: 14px;
            // Make the circle
            background-color: var(--list-cluster-background);
            border: 1px solid var(--list-cluster-border);
            border-radius: $cluster_small;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transition: transform .2s ease-in-out;
        }

        &.marker-wait-time {
            // overload the inline styles
            width: auto !important;
            height: auto !important;

            // wait time styling
            display: flex;
            align-items: center;
            text-align: center;
            --background-color: var(--finder-basic-white);
            background-color: var(--background-color);
            padding: 6px;
            border-radius: 15px;

            // [marker-size] This adjust the size of the marker
            min-width: 50px;
            min-height: 60px;

            filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.50));

            // Move the popup over where the pin should be
            left: calc(50% - 35px);
            top: -100px;

            strong {
                font-size: 20px;
                display: block;
                font-weight: 800;
            }

            &.selected {
                --background-color: #00233C;
                color: var(--finder-basic-white);
            }

            finder-operating-status {
                width: 100%;

                .wait-time {
                    font-size: 10px;
                }

                &::after {
                    position: absolute;
                    content: '';
                    width: 15px;
                    height: 15px;
                    transform: rotate(45deg);
                    bottom: -7px;
                    background-color: var(--background-color);
                    z-index: -1;
                    left: calc(50% - 8px);
                }
            }
        }
    }

    .icon-map {
        font-family: $icon-font;
        position: relative;
        padding-top: 35px;
        font-size: 22px;
        color: #000;
        text-shadow: -1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF;
    }

    .icon-hide .icon-map:after {
        display: none;
    }

    .icon-attractions:after {
        content: '\e141';
    }

    .icon-dining:after {
        content: '\e180';
    }

    .icon-entertainment:after {
        content: '\e143';
    }

    .icon-events-tours:after {
        content: '\e144';
    }

    .icon-guest-services:after {
        content: '\e201';
    }

    .icon-shops:after {
        content: '\e145';
    }

    .icon-recreation:after {
        content: '\e1d2';
    }

    .icon-spas:after {
        content: '\e146';
    }
}

// map-only view should always display the "List View"
// link even in mobile (but not zoom controls)
.map.map-only {
    .leaflet-right {
        &.leaflet-top,
        &.leaflet-bottom {
            display: block;
        }

        .leaflet-control-zoom {
            display: none;
        }
    }

    @include desktop-small {
        .leaflet-right {
            .leaflet-control-zoom {
                display: block;
            }
        }
    }
}

.map {
    .leaflet-popup-content-wrapper {
        width: 300px;
        min-height: 165px;
        box-sizing: border-box;
        display:flex;
    }

    // A11Y additions
    // We're using "border" instead of "outline" because Leaflet manually removes
    // outline styling from elements
    // https://github.com/Leaflet/Leaflet/blob/main/src/dom/DomUtil.js#L270
    .leaflet-control-zoom,
    .leaflet-control,
    .leaflet-popup {

        a:focus {
            border: 2px dotted var(--list-cluster-focus-control-border-color, #000);
            box-sizing: border-box;
            color: var(--list-cluster-focus-control-color, #000);
        }
    }

    // This is for the guest services page so the zoom controls stay when
    // the page is zoomed in
    &.keep-zoom-controls {
        .leaflet-right {
            &.leaflet-top,
            &.leaflet-bottom {
                display: block;
            }
        }
    }

    .leaflet-right {
        &.leaflet-top,
        &.leaflet-bottom {
            display: none;
        }
    }

    .land-name,
    .card-icon,
    .facet-list {
        color: var(--list-cluster-map-facet-text-color);
    }

    @include desktop-small {
        .leaflet-right {
            &.leaflet-top,
            &.leaflet-bottom {
                display: block;
            }
        }

        .leaflet-marker-icon {
            &:not(.leaflet-div-icon):focus {
                // dont move the outline styles order, the sass compiler removes var() before an !important
                outline: var(--list-cluster-focus-outline-color) 3px dotted !important;
                outline-offset: 2px;
            }

            &:focus {
                .marker-numbered {
                    outline: 3px dotted var(--list-cluster-focus-outline-color);
                    outline-offset: 2px;
                }
            }
            &.mouse-over {
                // Where there are a tight group of pins we need to surface the focused pin
                z-index: 1000 !important;
            }
        }
    }
}
